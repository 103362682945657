import React, { useCallback, useState, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { getEditorAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { openInlineCommentInEditor } from '@confluence/comments-util';
import { useInlineCommentsState } from '@confluence/inline-comments-hooks';
import {
	useInlineCommentsContext,
	useInlineCommentsDispatchContext,
} from '@confluence/comment-context';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	GeneralShortcutListener,
	NEXT_COMMENT_SHORTCUT_IN_EDIT,
	PREV_COMMENT_SHORTCUT_IN_EDIT,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
} from '@confluence/shortcuts';
import { handlePanelSelectionAndScroll } from '@confluence/comments-panel';
import { useCommentsPanel } from '@confluence/comments-panel-utils';
import { useObjectSidebarState, PanelName } from '@confluence/object-sidebar-api';
import { fg } from '@confluence/feature-gating';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { CommentType } from '@confluence/comments-data';

import { parsePanelAndInlineRefs, getNextRef } from './helper/commentsShortcutsHelper';

const EditorCommentShortcutListenerComponent = ({
	contentId,
	viewMode,
}: {
	contentId?: string;
	viewMode?: boolean;
}) => {
	const { navigateToInlineComment } = useInlineCommentsDispatchContext();
	const { unresolvedInlineComments } = useInlineCommentsState();
	const { removeCommentQueryParams } = useInlineCommentQueryParams();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { activeHighlight } = useInlineCommentsContext();
	const [isNextCommentLoading, setIsNextCommentLoading] = useState(false);
	const [
		{ currentlyRenderedThreads, currentlySelectedCommentMarkerRef },
		{ setCurrentlySelectedCommentMarkerRef },
	] = useCommentsPanel();
	const { isObjectSidebarShown, panel } = useObjectSidebarState();
	const pageMode = useGetPageMode();
	const emitter = getEditorAnnotationEventEmitter();

	useEffect(() => {
		setIsNextCommentLoading(false);
	}, [activeHighlight]);

	const onNavigationClick = useCallback(
		(nextMarkerRef?: string) => {
			if (!nextMarkerRef) return;

			emitter.emit('setselectedannotation', nextMarkerRef);
			const commentElement = document.getElementById(nextMarkerRef);
			if (commentElement) {
				openInlineCommentInEditor(commentElement, nextMarkerRef);
			}
		},
		[emitter],
	);

	const getCommentIndex = useCallback(
		(currentMarkerRef: string): number => {
			return unresolvedInlineComments?.findIndex((markerRef) => markerRef === currentMarkerRef);
		},
		[unresolvedInlineComments],
	);

	const handlePanelCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// If nothing is on the panel, there's nothing to navigate to
			if (currentlyRenderedThreads.length === 0) {
				return;
			}

			const { panelInlineCommentRefs, navigableInlineCommentRefs } = parsePanelAndInlineRefs(
				currentlyRenderedThreads,
				unresolvedInlineComments,
			);

			const nextRef = getNextRef(action, panelInlineCommentRefs, currentlySelectedCommentMarkerRef);

			// Equivalent to clicking on the comment in the panel
			handlePanelSelectionAndScroll({
				threadKey: nextRef,
				isResolved: !navigableInlineCommentRefs.has(nextRef),
				pageMode,
				setCurrentlySelectedCommentMarkerRef,
				eventEmitter: emitter,
				commentType: CommentType.INLINE,
			});
		},
		[
			currentlyRenderedThreads,
			unresolvedInlineComments,
			currentlySelectedCommentMarkerRef,
			pageMode,
			setCurrentlySelectedCommentMarkerRef,
			emitter,
		],
	);

	const handleInlineCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			const firstInlineCommentMarkerRef = document
				.querySelector('.ak-editor-annotation-blur')
				?.closest('[annotationtype="inlineComment"]')?.id;

			if (activeHighlight) {
				//Check if the next inline comment is still in process of being rendered. Otherwise, quickly pressing keys will force comment to open and close instead of navigating
				if (isNextCommentLoading) return;

				setIsNextCommentLoading(true);
				navigateToInlineComment({
					action,
					triggeredByKeyboardShortcut: true,
					currentCommentIndex: getCommentIndex(activeHighlight),
					onNavigationClick,
					isEditor: true,
					removeCommentQueryParams,
					unresolvedInlineComments,
					createAnalyticsEvent,
					contentId,
				});
			} else {
				onNavigationClick(firstInlineCommentMarkerRef);
			}
		},
		[
			activeHighlight,
			onNavigationClick,
			removeCommentQueryParams,
			unresolvedInlineComments,
			createAnalyticsEvent,
			contentId,
			getCommentIndex,
			navigateToInlineComment,
			isNextCommentLoading,
		],
	);

	const handleCommentNavigation = useCallback(
		(action: 'next' | 'previous') => {
			// Define behavior when the comments panel is shown - panel FG is a prereq for nav, so has to be checked first
			if (
				// eslint-disable-next-line confluence-feature-gating/no-preconditioning
				fg('confluence-frontend-comments-panel') &&
				isObjectSidebarShown &&
				panel?.id === PanelName.CommentsPanel
			) {
				handlePanelCommentNavigation(action);
			} else {
				// Comments panel not shown
				handleInlineCommentNavigation(action);
			}
		},
		[isObjectSidebarShown, panel?.id, handlePanelCommentNavigation, handleInlineCommentNavigation],
	);

	return (
		<div data-testId="comments-shortcut-listener">
			<GeneralShortcutListener
				accelerator={viewMode ? PREV_COMMENT_SHORTCUT : PREV_COMMENT_SHORTCUT_IN_EDIT}
				listener={() => handleCommentNavigation('previous')}
			/>
			<GeneralShortcutListener
				accelerator={viewMode ? NEXT_COMMENT_SHORTCUT : NEXT_COMMENT_SHORTCUT_IN_EDIT}
				listener={() => handleCommentNavigation('next')}
			/>
		</div>
	);
};

export const EditorCommentShortcutListener = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(EditorCommentShortcutListenerComponent);
